<template>
    <multiselect
        id="table-selector"
        label="name"
        track-by="id"
        v-model="query"
        :options="tables"
        :showLabels="false"
        :searchable="true"
        :allowEmpty="true"
        :placeholder="placeholder"
        @search-change="searchDebounce"
        @select="onSelect"
        @remove="$emit('remove', $event)"
        class="with-border table-selector"
    >
        <template slot="caret">
            <div v-if="!loading" class="chevron">
                <ChevronDown />
            </div>
            <div v-else class="icon">
                <b-spinner small type="grow" variant="primary"></b-spinner>
            </div>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
            {{ option.name }}
        </template>
        <template slot="option" slot-scope="{ option }">
            {{ option.name }}
        </template>
        <template slot="noOptions">Realize uma busca para obter resultados</template>
        <template slot="noResult">Nenhum resultado encontrado</template>
    </multiselect>
</template>
<script>
import api from '@tables/api'
import { debounce } from 'lodash';
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    props: {
        clinicHealthPlanTables: Array,
        clinicHealthPlan: Object,
        types: Array,
        value: Object,
        placeholder: String,
    },
    mounted() {
        this.searchDebounce = debounce(this.searchTables, 300);
        // this.searchTables();
        this.populateValue();
    },
    data() {
        return {
            loading: false,
            clinic: getCurrentClinic(),
            query: null,
            tables: [],
            searchDebounce: Function
        }
    },
    methods: {
        searchTables(query) {
            if (this.tables.length && !query) return
            this.loading = true;
            api.getHealthPlanTables(this.clinicHealthPlan.id, query, this.types, this.clinicHealthPlan.clinic_id)
                .then(({ data }) => {
                    this.tables = data.filter(
                        el => !this.clinicHealthPlanTables.filter(clinicHealthPlanTable => !['MATERIAL', 'MEDICINE'].includes(clinicHealthPlanTable.sub_type))
                            .some(clinicHealthPlanTable => clinicHealthPlanTable.table_id === el.id)
                );
                })
                .catch(error => this.$toast.error(error.message))
                .finally(() => (this.loading = false));
        },
        onSelect(table) {
            this.$emit('select', table);
            this.$emit('input', table);
        },
        populateValue() {       
            if (this.value?.id) {
                this.tables = [this.value];
                this.query = this.value;
                return;
            }
            this.tables = [];
            this.query = null;
        },
    },
    watch: {
        value() {
            this.populateValue();
        }
    }
    
}
</script>
<style lang="scss" scoped>
.table-selector {
    width: 100%;
}
.icon {
    display: flex;
    align-items: center;
    width: 24px;
    height:38px;
    padding: 0;
    right: 16px;
    position: absolute;
    z-index: 100;
    svg {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
    }
}
</style>